
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const email = ref('')

    return {
      email,
    }
  },
})
